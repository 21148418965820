<template>
  <app-form-view
    app="audit"
    model="auditplanheader"
    api-url="audit/audit-plan-header/"
    :title="$t('menu.section')"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveItems"
    v-model="formData"
    :prepare-data="prepareData"
  >
    <template v-slot="{ view, mode }">
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="name"
            rules="required"
            :label="$t('fields.sectionName')"
            :view="view"
            v-model="formData.name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_name"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="reference"
            rules="required"
            :isUpperCase="true"
            :label="$t('fields.reference')"
            :view="view"
            v-model="formData.reference"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveReference"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_reference"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="auditPeriod"
            rules="required"
            :label="$t('fields.auditPeriod')"
            :view="view"
            v-model="formData.audit_period"
            type="select"
            :items="[
              {
                label: 'Q1',
                value: 'q1'
              },
              {
                label: 'Q2',
                value: 'q2'
              },
              {
                label: 'Q3',
                value: 'q3'
              },
              {
                label: 'Q4',
                value: 'q4'
              },
              { label: 'Year End', value: 'year_end' },
              {
                label: 'Customs',
                value: 'customs'
              }
            ]"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAuditPeriod"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_audit_period"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="contactID"
            rules="required"
            type="select-server"
            :label="$t('fields.contactName')"
            :view="view"
            :binds="{
              apiUrl: 'contact/contact/?active=true'
            }"
            v-model="formData.contact_id"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveContactID"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_contact_id"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="auditYear"
            rules="required"
            type="year-selection"
            :label="$t('fields.auditYear')"
            :view="view"
            v-model="formData.audit_year"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAuditYear"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_audit_year"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="accountingPeriod"
            rules="required"
            type="datepicker"
            :label="$t('fields.accountingPeriod')"
            :view="view"
            v-model="formData.accounting_period"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAccountingPeriod"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_accounting_period"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="deadline"
            rules="required"
            type="datepicker"
            :label="$t('fields.deadline')"
            :view="view"
            v-model="formData.deadline"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveDeadline"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_deadline"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="preparedBy"
            type="select-server"
            :label="$t('fields.preparedBy')"
            :view="view"
            :binds="{
              apiUrl: `contact/contact/?active=true&audit_plan_id=${auditPlanId}`
            }"
            v-model="formData.prepare_by"
          />
          <app-input
            v-if="isSentToApprove"
            name="approvePreparedBy"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_prepare_by"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="prepareDate"
            type="datepicker"
            :label="$t('fields.prepareDate')"
            :view="view"
            v-model="formData.prepare_date"
          />
          <app-input
            v-if="isSentToApprove"
            name="approvePrepareDate"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_prepare_date"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="reviewedBy"
            type="select-server"
            :label="$t('fields.reviewedBy')"
            :view="view"
            :binds="{
              apiUrl: `contact/contact/?active=true&audit_plan_id=${auditPlanId}`
            }"
            v-model="formData.review_by"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveReviewedBy"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_review_by"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="reviewDate"
            type="datepicker"
            :label="$t('fields.reviewDate')"
            :view="view"
            v-model="formData.review_date"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveReviewDate"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_review_date"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="materialityOverall"
            type="number"
            :label="$t('fields.materialityOverall')"
            :view="view"
            v-model="formData.materiality_overall"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveMaterialityOverall"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_materiality_overall"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="noteMaterialityOverall"
            :label="$t('fields.noteMaterialityOverall')"
            :view="view"
            v-model="formData.note_materiality_overall"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveNoteMaterialityOverall"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_note_materiality_overall"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="materialityAccount"
            type="number"
            :label="$t('fields.materialityAccount')"
            :view="view"
            v-model="formData.materiality_account"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveMaterialityAccount"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_materiality_account"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="noteMaterialityAccount"
            :label="$t('fields.noteMaterialityAccount')"
            :view="view"
            v-model="formData.note_materiality_account"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveNoteMaterialityAccount"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_note_materiality_account"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="materialityItem"
            type="number"
            :label="$t('fields.materialityItem')"
            :view="view"
            v-model="formData.materiality_item"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveMaterialityItem"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_materiality_item"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="noteMaterialityItem"
            :label="$t('fields.noteMaterialityItem')"
            :view="view"
            v-model="formData.note_materiality_item"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveNoteMaterialityItem"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_note_materiality_item"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="accountingPeriodBeginning"
            type="datepicker"
            :label="$t('fields.accountingPeriodBeginning')"
            :view="view"
            v-model="formData.accounting_period_beginning"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAccountingPeriodBeginning"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_accounting_period_beginning"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="accountingPeriodEnding"
            type="datepicker"
            :label="$t('fields.accountingPeriodEnding')"
            :view="view"
            v-model="formData.accounting_period_ending"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAccountingPeriodEnding"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_accounting_period_ending"
          />
        </v-col>
      </v-row>

      <v-expansion-panels multiple>
        <!-- Audit Panel -->
        <app-expansion-panel :headerText="$t('menu.audit')">
          <v-expansion-panels multiple>
            <!-- Audit Program Panel -->
            <app-expansion-panel :headerText="$t('menu.auditPlan')">
              <app-audit-plan :mode="mode" :view="view" />
            </app-expansion-panel>
            <!-- Pre Audit Panel -->
            <app-expansion-panel :headerText="$t('menu.preAudit')">
              <app-audit
                :title="$t('menu.preAudit')"
                :taskItemHeader="preAuditTaskItemHeader"
                :auditTasks="preAuditTaskItemItems"
                :loading="loading"
                :auditTasksLength="preAuditTaskItemServerItemsLength"
                :mode="mode"
                :hideEdit="mode !== 'edit'"
                :hideDelete="mode !== 'edit'"
                :serverFetch="getPreAuditTaskItemItems"
                :deleteTask="onDeletePreAuditTaskItemItem"
                @editTask="onEditTask"
                @createTask="onCreateTask"
              />
            </app-expansion-panel>
            <!-- Audit Panel -->
            <app-expansion-panel :headerText="$t('menu.audit')">
              <app-audit
                :title="$t('menu.audit')"
                :taskItemHeader="auditTaskItemHeader"
                :auditTasks="auditTaskItemItems"
                :loading="loading"
                :auditTasksLength="auditTaskItemServerItemsLength"
                :mode="mode"
                :hideEdit="mode !== 'edit'"
                :hideDelete="mode !== 'edit'"
                :serverFetch="getAuditTaskItemItems"
                :deleteTask="onDeleteAuditHeaderTaskItemItem"
                @editTask="onEditTask"
                @createTask="onCreateTask"
              />
            </app-expansion-panel>
            <!-- Post Audit Panel -->
            <app-expansion-panel :headerText="$t('menu.postAudit')">
              <app-audit
                :title="$t('menu.postAudit')"
                :taskItemHeader="postAuditTaskItemHeader"
                :auditTasks="postAuditTaskItemItems"
                :loading="loading"
                :auditTasksLength="postAuditTaskItemServerItemsLength"
                :mode="mode"
                :hideEdit="mode !== 'edit'"
                :hideDelete="mode !== 'edit'"
                :serverFetch="getPostAuditTaskItemItems"
                :deleteTask="onDeletePostAuditTaskItemItem"
                @editTask="onEditTask"
                @createTask="onCreateTask"
              />
            </app-expansion-panel>
          </v-expansion-panels>
        </app-expansion-panel>
        <!-- Member Panel -->
        <app-expansion-panel :headerText="$t('menu.member')">
          <app-menu-form
            :headers="memberItemHeader"
            :server-items="memberItemItems"
            :client-items="memberItemItems"
            :loading="loading"
            :server-items-length="memberItemServerItemsLength"
            :hide-delete="mode !== 'edit'"
            :hide-edit="mode !== 'edit'"
            :events="{
              server: getMemberItemItems,
              delete: onDeleteMemberItemItem,
              edit: onEditMemberItem
            }"
          >
            <template v-slot:top>
              <app-menu-form-top
                :mode="mode"
                title="Member"
                :selected-length="selected.length"
                @on-delete="onDeleteMemberItemItem"
                dialog-max-width="900px"
                :dialog="dialogMemberItem"
                @show-dialog="dialogMemberItem = $event"
              >
                <template v-slot:dialogForm>
                  <v-card>
                    <v-card-text>
                      <v-container v-if="editId === null">
                        <v-row>
                          <v-col cols="12" sm="6">
                            <app-input
                              name="typeMember"
                              type="select"
                              :label="$t('fields.type')"
                              :view="view"
                              v-model="editedMemberItemItem.type_member"
                              :items="[
                                {
                                  label: 'Team',
                                  value: 'team'
                                },
                                {
                                  label: 'Member',
                                  value: 'member'
                                }
                              ]"
                            ></app-input>
                          </v-col>
                        </v-row>
                        <v-row
                          v-if="editedMemberItemItem.type_member === 'team'"
                        >
                          <v-col cols="12" sm="12">
                            <app-table
                              server-side
                              hide-edit
                              hide-delete
                              app="team"
                              model="teamheader"
                              :headers="teamItemHeader"
                              :serverItems="teamItemItems"
                              :clientItems="teamItemItems"
                              :loading="loading"
                              :server-items-length="teamItemServerItemsLength"
                              v-model="selectedMember"
                              @server="getTeams"
                            />
                          </v-col>
                        </v-row>
                        <v-row
                          v-if="editedMemberItemItem.type_member === 'member'"
                        >
                          <v-col cols="12" sm="12">
                            <app-table
                              server-side
                              hide-edit
                              hide-delete
                              app="contact"
                              model="contact"
                              :headers="empItemHeader"
                              :serverItems="empItemItems"
                              :clientItems="empItemItems"
                              :loading="loading"
                              :server-items-length="empItemServerItemsLength"
                              v-model="selectedMember"
                              @server="getEmployee"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-container v-else>
                        <v-row>
                          <v-col cols="12" sm="12">
                            <app-input
                              name="position"
                              :label="$t('fields.position')"
                              :view="view"
                              type="select-server"
                              v-model="editedMemberItemItem.position"
                              :binds="{
                                apiUrl:
                                  'audit/master-position/?active=true&state=approved'
                              }"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="closeMemberItem"
                      >
                        Cancel
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="saveMemberItem">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </app-menu-form-top>
            </template>
          </app-menu-form>
        </app-expansion-panel>
        <!-- Matrix Panel -->
        <app-expansion-panel :headerText="$t('menu.matrix')">
          <v-expansion-panels multiple>
            <!-- Activity Matrix Panel -->
            <app-expansion-panel :headerText="$t('menu.activityMatrix')">
              <app-activity-matrix :isPlan="false" />
            </app-expansion-panel>
            <!-- Risk Matrix Panel -->
            <app-expansion-panel :headerText="$t('menu.riskMatrix')">
              <app-risk-matrix :isPlan="false" />
            </app-expansion-panel>
          </v-expansion-panels>
        </app-expansion-panel>
        <!-- Report Panel -->
        <app-expansion-panel :headerText="$t('menu.report')">
          <!-- timesheet by position Panel -->
          <app-timesheet-position />
          <!-- timesheet by member Panel -->
          <app-timesheet-member />
        </app-expansion-panel>
      </v-expansion-panels>
    </template>
  </app-form-view>
</template>

<script>
import AppTable from '@components/AppTable'
import AppInput from '@components/AppInput'
import AppFormView from '@components/AppFormView'
import AppExpansionPanel from '@components/AppExpansionPanel.vue'
import AppAuditPlan from '@components/view/AppAuditPlan'
import AppAudit from '@components/view/AppAudit'
import AppRiskMatrix from '@components/view/AppRiskMatrix'
import AppActivityMatrix from '@components/view/AppActivityMatrix'
import AppTimesheetMember from '@components/view/AppTimesheetMember'
import AppTimesheetPosition from '@components/view/AppTimesheetPosition'
import AppMenuForm from '@components/AppMenuForm'
import AppMenuFormTop from '@components/view/AppMenuFormTop'
import { defaultTableParams } from '@utils/app-table'
import { hoursMinutes, percentFormat } from '@utils/number-format'

export default {
  name: 'audit-plan-form',
  components: {
    AppTable,
    AppInput,
    AppFormView,
    AppExpansionPanel,
    AppAuditPlan,
    AppAudit,
    AppTimesheetMember,
    AppTimesheetPosition,
    AppRiskMatrix,
    AppActivityMatrix,
    AppMenuForm,
    AppMenuFormTop
  },
  data() {
    return {
      formData: {
        state: 'draft'
      },
      tab: null,
      loading: false,
      apiMethod: 'post',
      selected: [],
      selectedMember: [],
      approveItems: [
        'approve_name',
        'approve_reference',
        'approve_audit_period',
        'approve_contact_id',
        'approve_audit_year',
        'approve_accounting_period',
        'approve_deadline',
        'approve_prepare_by',
        'approve_prepare_date',
        'approve_review_by',
        'approve_review_date',
        'approve_materiality_overall',
        'approve_note_materiality_overall',
        'approve_materiality_account',
        'approve_note_materiality_account',
        'approve_materiality_item',
        'approve_note_materiality_item',
        'approve_accounting_period_beginning',
        'approve_accounting_period_ending'
      ],
      editedItem: {
        document_template_id: null
      },
      editId: null,
      requiredDocumentItems: [],
      requiredDocumentClientItems: [],
      requiredDocumentSelected: [],
      requiredDocumentItemsLength: 0,
      dialogRequiredDocument: false,
      memberItemItems: [],
      memberItemServerItemsLength: 0,
      dialogMemberItem: false,
      editedMemberItemItem: [],
      teamItemItems: [],
      teamItemServerItemsLength: 0,
      empItemItems: [],
      empItemServerItemsLength: 0,
      preAuditTaskItemItems: [],
      preAuditTaskItemServerItemsLength: 0,
      auditTaskItemItems: [],
      auditTaskItemServerItemsLength: 0,
      postAuditTaskItemItems: [],
      postAuditTaskItemServerItemsLength: 0,
      timesheetItemItems: [],
      timesheetItemServerItemsLength: 0,
      dialogAuditPlanItem: false,
      auditPlanItemItems: [],
      auditPlanItemServerItemsLength: 0,
      editedAuditPlan: [],
      selectedSuggest: [],
      suggestionItemItems: [],
      suggestionItemServerItemsLength: 0,
      timesheetByPositions: [],
      timesheetByPositionsCount: 0,
      timesheetByPositionSumStandardManDay: 0,
      timesheetByPositionSumAssignedManDay: 0,
      timesheetByPositionSumUnassigned: 0,
      timesheetByPositionSumAssignedProgress: 0,
      timesheetByPositionSumFinishedAssignedManDay: 0,
      timesheetByPositionSumActualManDay: 0,
      timesheetByPositionSumVariance: 0,
      timesheetByPositionSumTaskPerformance: 0,
      timesheetByPositionSumWorkProgress: 0,
      timesheetByPositionSumWeight: 0,
      timesheetByMembers: [],
      timesheetByMembersCount: 0,
      timesheetByMemberSumStandardManDay: 0,
      timesheetByMemberSumAssignedManDay: 0,
      timesheetByMemberSumWeight: 0,
      timesheetByMemberSumFinishedAssignedManDay: 0,
      timesheetByMemberSumActualManDay: 0,
      timesheetByMemberSumVariance: 0,
      timesheetByMemberSumTaskPerformance: 0,
      timesheetByMemberSumWorkProgress: 0,
      timesheetByMemberIdItems: {},
      timesheetByMemberIdItemsCount: {},
      auditPlanId: null
    }
  },
  watch: {
    'formData.name': function (value) {
      if (this.$route.name === 'auditPlanEdit') {
        this.$store.dispatch('addBreadcrumb', {
          name: 'auditPlanEdit',
          text: value,
          to: {
            name: 'auditPlanEdit',
            params: { id: this.$route.params.id }
          }
        })
      }
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    },
    requiredDocumentHeader() {
      return [
        {
          text: this.$t('fields.requiredDocument'),
          value: 'document_template_id.name'
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    preAuditTaskItemHeader() {
      return [
        {
          text: this.$t('fields.taskName'),
          value: 'name'
        },
        {
          text: this.$t('fields.assignTo'),
          value: 'assign_to.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.assignDate'),
          value: 'assign_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.preparedBy'),
          value: 'prepare_by.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.prepareDate'),
          value: 'prepare_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.reviewedBy'),
          value: 'review_by.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.reviewDate'),
          value: 'review_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.deadline'),
          value: 'deadline',
          hideFilter: true
        },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions_view',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    auditTaskItemHeader() {
      return [
        {
          text: this.$t('fields.taskName'),
          value: 'name'
        },
        {
          text: this.$t('fields.assignTo'),
          value: 'assign_to.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.assignDate'),
          value: 'assign_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.preparedBy'),
          value: 'prepare_by.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.prepareDate'),
          value: 'prepare_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.reviewedBy'),
          value: 'review_by.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.reviewDate'),
          value: 'review_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.deadline'),
          value: 'deadline',
          hideFilter: true
        },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions_view',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    postAuditTaskItemHeader() {
      return [
        {
          text: this.$t('fields.taskName'),
          value: 'name'
        },
        {
          text: this.$t('fields.assignTo'),
          value: 'assign_to.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.assignDate'),
          value: 'assign_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.preparedBy'),
          value: 'prepare_by.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.prepareDate'),
          value: 'prepare_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.reviewedBy'),
          value: 'review_by.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.reviewDate'),
          value: 'review_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.deadline'),
          value: 'deadline',
          hideFilter: true
        },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions_view',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    memberItemHeader() {
      return [
        {
          text: this.$t('fields.memberName'),
          value: 'contact_id.name'
        },
        {
          text: this.$t('fields.position'),
          value: 'position.name'
        },
        {
          text: this.$t('fields.team'),
          value: 'team_id.name'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    timesheetItemHeader() {
      return [
        {
          text: this.$t('fields.team'),
          value: 'team_name',
          hideFilter: true
        },
        {
          text: this.$t('fields.name'),
          value: 'contact_name',
          hideFilter: true
        },
        {
          text: this.$t('fields.position'),
          value: 'position',
          hideFilter: true
        },
        {
          text: this.$t('fields.expectedManDay'),
          value: 'expected_man_day_converted',
          hideFilter: true
        },
        {
          text: this.$t('fields.actualManDay'),
          value: 'actual_man_day_converted',
          hideFilter: true
        }
      ]
    },
    teamItemHeader() {
      return [
        {
          text: this.$t('fields.team'),
          value: 'name'
        },
        {
          text: this.$t('fields.regionName'),
          value: 'teamRegion_id.region_id.name'
        },
        {
          text: this.$t('fields.divisionName'),
          value: 'teamRegion_id.division_id.name'
        },
        {
          text: this.$t('fields.subDivisionName'),
          value: 'teamRegion_id.sub_division_id.name'
        }
      ]
    },
    empItemHeader() {
      return [
        {
          text: this.$t('fields.name'),
          value: 'name'
        }
      ]
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.getDataFromAuditPlanMaster()
    }
  },
  created() {
    this.auditPlanId = this.$route.params.id || 0
    if (this.$route.name === 'auditPlanCreate') {
      this.$store.dispatch('addBreadcrumb', {
        name: 'auditPlanCreate'
      })
    }
  },
  methods: {
    hoursMinutesFormat: hoursMinutes,
    percentFormat: percentFormat,
    timeHrsAutoConvert(value, return_int = false) {
      let _value = value

      if (_value || _value == 0) {
        _value = value.toString()
        if (_value.includes(':')) {
          const [hours, minutes] = _value.split(':').map(Number)
          _value = `${hours.toString().padStart(2, '0')}:${Math.min(minutes, 59)
            .toString()
            .padStart(2, '0')}`
        } else {
          const totalMinutes = parseInt(_value, 10)
          const hours = Math.floor(totalMinutes / 60)
            .toString()
            .padStart(2, '0')
          const minutes = (totalMinutes % 60).toString().padStart(2, '0')
          _value = `${hours}:${minutes}`
        }

        if (return_int) {
          if (_value.indexOf(':') > -1) {
            const [hours, minutes] = _value.split(':').map(Number)
            _value = hours * 60 + minutes
          } else {
            _value = 0
          }
        }
      }

      return _value
    },
    getTeams() {
      this.$api({
        method: 'get',
        url: `team/team-header/?state=approved&active=true`,
        hideSuccessAlert: true
      }).then(({ data }) => {
        this.teamItemServerItemsLength = data.count
        this.teamItemItems = data.results
      })
      this.loading = false
    },
    getEmployee() {
      this.$api({
        method: 'get',
        url: `contact/contact/?state=approved&is_employee=true&active=true`,
        hideSuccessAlert: true
      }).then(({ data }) => {
        this.empItemServerItemsLength = data.count
        this.empItemItems = data.results
      })
      this.loading = false
    },

    getRequiredDocumentItems(options = null) {
      this.loading = true

      if (options) {
        const offset = (options.page - 1) * options.itemsPerPage
        var ordering = options.sortBy
          .reduce(
            (acc, value, index) => [
              ...acc,
              `${options.sortDesc[index] ? '-' : ''}${value.replace('.', '__')}`
            ],
            []
          )
          .join(',')

        if (options.sortBy.length == 0) {
          ordering = 'document_template_id'
        }

        this.params = {
          limit: options.itemsPerPage,
          offset: offset,
          search: options.search,
          ordering: ordering,
          ...options.multiSearch,
          audit_plan_header_id: this.$route.params.id
        }
      } else {
        this.params = {
          audit_plan_header_id: this.$route.params.id,
          ordering: 'document_template_id'
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: 'audit/audit-required-document/',
          params: this.params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.requiredDocumentItemsLength = data.count
          this.requiredDocumentItems = data.results
          this.requiredDocumentClientItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getRequiredDocumentClientItems(multiSearch) {
      this.requiredDocumentClientItems = this.requiredDocumentItems.filter(
        (item) => {
          return Object.entries(multiSearch).every(([key, value]) => {
            if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
              return item[key.slice(0, key.lastIndexOf('.'))][
                key.slice(key.lastIndexOf('.') + 1)
              ]
                .toString()
                .toUpperCase()
                .includes(value.toString().toUpperCase())
            } else {
              return item[key]
                .toString()
                .toUpperCase()
                .includes(value.toString().toUpperCase())
            }
          })
        }
      )
    },
    closeRequiredDocument() {
      this.editedItem = {}
      this.dialogRequiredDocument = false
    },
    saveRequiredDocument() {
      this.loading = true
      this.editedItem.audit_plan_header_id = this.$route.params.id

      this.editedItem.document_template_id =
        this.editedItem.document_template_id.id

      const data = this.editedItem

      this.$api({
        method: this.apiMethod,
        url: `audit/audit-required-document/${
          this.editId ? this.editId + '/' : ''
        }`,
        data
      })

      this.loading = false
      this.apiMethod = 'post'
      this.editId = null
      this.closeRequiredDocument()
      this.getRequiredDocumentItems()
    },
    onEdit(item) {
      this.editedItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
      this.dialogRequiredDocument = true
    },
    onDeleteItem(item) {
      this.onDelete(item)
    },
    onDelete(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/audit-required-document/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.$refs.table.onServer()
        })
      })
    },
    onCreateTask() {
      this.$router.push({
        name: 'taskCreate',
        query: {
          audit_plan_id: this.$route.params.id,
          from: 'audit-plan-header',
          accounting_period_beginning:
            this.formData.accounting_period_beginning,
          accounting_period_ending: this.formData.accounting_period_ending
        }
      })
    },
    onEditTask(item) {
      this.$router.push({
        name: 'taskEdit',
        params: { id: item.id },
        query: {
          audit_plan_id: this.$route.params.id,
          from: 'audit-plan-header'
        }
      })
    },
    getPreAuditTaskItemItems() {
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `/task/task/?audit_plan_id=${this.$route.params.id}&task_template_header_id__is_pre_audit=true`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.preAuditTaskItemServerItemsLength = data.count
          this.preAuditTaskItemItems = data.results
        })
      }
      this.loading = false
    },
    onDeletePreAuditTaskItemItem(item) {
      this.onDeletePreAuditTaskItem(item)
    },
    onDeletePreAuditTaskItem(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getPreAuditTaskItemItems()
        })
      })
      this.loading = false
    },

    getAuditTaskItemItems() {
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `/task/task/?audit_plan_id=${this.$route.params.id}&task_template_header_id__is_audit=true`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.auditTaskItemServerItemsLength = data.count
          this.auditTaskItemItems = data.results
        })
      }
      this.loading = false
    },
    onDeleteAuditHeaderTaskItemItem(item) {
      this.onDeleteAuditHeaderTaskItem(item)
    },
    onDeleteAuditHeaderTaskItem(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getAuditTaskItemItems()
        })
      })
      this.loading = false
    },

    getPostAuditTaskItemItems() {
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `/task/task/?audit_plan_id=${this.$route.params.id}&task_template_header_id__is_post_audit=true`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.postAuditTaskItemServerItemsLength = data.count
          this.postAuditTaskItemItems = data.results
        })
      }
      this.loading = false
    },
    onDeletePostAuditTaskItemItem(item) {
      this.onDeletePostAuditTaskItem(item)
    },
    onDeletePostAuditTaskItem(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getPostAuditTaskItemItems()
        })
      })
      this.loading = false
    },

    getMemberItemItems() {
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `audit/audit-plan-member/?audit_plan_header_id=${this.$route.params.id}`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.memberItemServerItemsLength = data.count
          this.memberItemItems = data.results
        })
      }
      this.loading = false
    },
    onDeleteMemberItemItem(item) {
      this.onDeleteMemberItem(item)
    },
    onDeleteMemberItem(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/audit-plan-member/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getMemberItemItems()
          this.getMembers()
        })
      })
      this.loading = false
    },
    closeMemberItem() {
      this.dialogMemberItem = false
      this.$nextTick(() => {
        this.editedMemberItemItem = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
      this.editId = null
      this.selectedMember = []
      this.apiMethod = 'post'
      this.getMembers()
      this.loading = false
    },
    async saveMemberItem() {
      this.loading = true
      const data = {}
      data.audit_plan_header_id = parseInt(this.$route.params.id)
      if (this.editId !== null && this.editId !== undefined) {
        if (
          this.editedMemberItemItem.contact_id !== null &&
          this.editedMemberItemItem.contact_id !== undefined
        ) {
          data.contact_id = this.editedMemberItemItem.contact_id.id
        }
        data.position = this.editedMemberItemItem.position?.id
          ? this.editedMemberItemItem.position.id
          : this.editedMemberItemItem.position
      } else {
        data.type = this.editedMemberItemItem.type_member
        data.ids = this.selectedMember.map((value) => value.id)
      }

      await this.$api({
        method: this.apiMethod,
        url: `/audit/audit-plan-member/${this.editId ? this.editId + '/' : ''}`,
        data
      })
      this.loading = false
      this.getMemberItemItems()
      this.closeMemberItem()
    },
    onEditMemberItem(item) {
      this.editedMemberItemItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
      this.dialogMemberItem = true
    },

    getTimesheetItemItems() {
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `audit/audit-plan-timesheet-summary/?audit_plan_header_id=${this.$route.params.id}`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.timesheetItemServerItemsLength = data.count
          this.timesheetItemItems = data.results.map((item) => ({
            ...item,
            expected_man_day_converted: this.timeHrsAutoConvert(
              item.expected_man_day
            ),
            actual_man_day_converted: this.timeHrsAutoConvert(
              item.actual_man_day
            )
          }))
        })
      }
      this.loading = false
    },

    getDataFromAuditPlanMaster() {
      this.$api({
        method: 'get',
        url: `audit/audit-plan-header/${this.$route.params.id}`,
        hideSuccessAlert: true
      }).then(({ data }) => {
        this.$nextTick(() => {
          this.editedAuditPlan.contact_id = data.detail.contact_id
        })
      })
    },
    getAuditPlanItemItems(options = null) {
      this.loading = true

      if (options) {
        const localOptions = { ...options }

        const offset = (localOptions.page - 1) * localOptions.itemsPerPage
        var ordering = localOptions.sortBy
          .reduce(
            (acc, value, index) => [
              ...acc,
              `${localOptions.sortDesc[index] ? '-' : ''}${value.replace(
                '.',
                '__'
              )}`
            ],
            []
          )
          .join(',')

        if (localOptions.sortBy.length === 0) {
          ordering = 'sequence'
        }

        this.params = {
          limit: localOptions.itemsPerPage,
          offset: offset,
          search: localOptions.search,
          ordering: ordering,
          ...localOptions.multiSearch,
          audit_plan_id: this.$route.params.id
        }
      } else {
        this.params = {
          audit_plan_id: this.$route.params.id,
          ordering: 'sequence'
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `task/task/`,
          params: this.params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.auditPlanItemServerItemsLength = data.count
          this.auditPlanItemItems = data.results
          this.loading = false
        })
      } else {
        this.loading = false
      }
    },
    onDeleteAuditTaskItemItem(item) {
      this.onDeleteAuditTaskItem(item)
    },
    onDeleteAuditTaskItem(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getAuditPlanItemItems()
        })
      })
      this.loading = false
    },
    async saveAuditPlanItem() {
      this.loading = true
      const data = {}
      data.audit_plan_id = this.$route.params.id
      if (
        this.editedAuditPlan.contact_id !== null &&
        this.editedAuditPlan.contact_id !== undefined
      ) {
        data.contact_id = this.editedAuditPlan.contact_id.id
      }
      if (
        this.editedAuditPlan.suggest_type !== null &&
        this.editedAuditPlan.suggest_type !== undefined
      ) {
        data.suggest_type = this.editedAuditPlan.suggest_type
      }
      data.ids = this.selectedSuggest.map((value) => value.audit_program_id)

      await this.$api({
        method: this.apiMethod,
        url: `/audit/audit-plan-suggest/`,
        data
      })

      this.loading = false
      this.getAuditPlanItemItems()
      this.closeAuditPlanItem()
    },
    closeAuditPlanItem() {
      this.editedAuditPlan = {}
      this.dialogAuditPlanItem = false
    },
    prepareData(data) {
      data.contact_id = data.contact_id.id
      data.prepare_by = data?.prepare_by?.id
      data.review_by = data?.review_by?.id
      return data
    },
    async getTimesheetByPositions(options = null) {
      if (
        this.$route.params.id === null ||
        this.$route.params.id === undefined
      ) {
        return
      }
      this.loading = true
      try {
        let params = defaultTableParams(options)
        params.audit_plan_id = this.$route.params.id
        const { data } = await this.$api({
          method: 'get',
          url: `audit/audit/timesheet-by-position/`,
          hideSuccessAlert: true,
          params: params
        })
        this.timesheetByPositions = data.results
        this.timesheetByPositionsCount = data.count

        this.timesheetByPositionSumStandardManDay = data.sum_standard_man_day
        this.timesheetByPositionSumAssignedManDay = data.sum_expected_man_day
        this.timesheetByPositionSumUnassigned = data.sum_unassigned
        this.timesheetByPositionSumAssignedProgress = data.sum_assigned_progress
        this.timesheetByPositionSumFinishedAssignedManDay =
          data.sum_finished_assigned_man_day
        this.timesheetByPositionSumActualManDay = data.sum_actual_man_day
        this.timesheetByPositionSumVariance = data.sum_variance
        this.timesheetByPositionSumTaskPerformance = data.sum_task_performance
        this.timesheetByPositionSumWorkProgress = data.sum_work_progress
        this.timesheetByPositionSumWeight = data.sum_weight
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getTimesheetByMembers(options = null) {
      if (
        this.$route.params.id === null ||
        this.$route.params.id === undefined
      ) {
        return
      }
      this.loading = true
      try {
        let params = defaultTableParams(options)
        params.audit_plan_id = this.$route.params.id
        const { data } = await this.$api({
          method: 'get',
          url: `audit/audit/timesheet-by-member/`,
          hideSuccessAlert: true,
          params: params
        })
        this.timesheetByMembers = data.results
        this.timesheetByMembersCount = data.count
        this.timesheetByMemberSumStandardManDay = data.sum_standard_man_day
        this.timesheetByMemberSumAssignedManDay = data.sum_expected_man_day
        this.timesheetByMemberSumWeight = data.sum_weight
        this.timesheetByMemberSumFinishedAssignedManDay =
          data.sum_finished_assigned_man_day
        this.timesheetByMemberSumActualManDay = data.sum_actual_man_day
        this.timesheetByMemberSumVariance = data.sum_variance
        this.timesheetByMemberSumTaskPerformance = data.sum_task_performance
        this.timesheetByMemberSumWorkProgress = data.sum_work_progress
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getTimesheetByMemberIdItems(item, value, options) {
      if (!value || !options) return
      this.loading = true
      try {
        let params = defaultTableParams(options)

        params.audit_plan_id = this.$route.params.id
        const { data } = await this.$api({
          method: 'get',
          url: `audit/audit/timesheet-by-member/${item.member_id}/`,
          params: params,
          hideSuccessAlert: true
        })
        this.timesheetByMemberIdItems[item.member_id] = data.results
        this.timesheetByMemberIdItemsCount[item.member_id] = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
